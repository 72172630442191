<div #toDoListContainer [ngClass]="{ 'to-do-list--items-container': true, 'myday-popup-container': !showHeader }">
	<div *ngIf="showHeader" class="todo-list-container">
		<div class="title-container">
			<div class="title-shadow-box">
				<!-- <div class="dashboard-form-header"> -->
				<div class="widget-title-sec widget-title-fbox">
					<span class="widget-title">My Day</span>
					<div class="todo-icon">
						<span id="{{ elementIdPrefix + 'viewAllToDoBtn' }}" *ngIf="this.viewToDoEvent" style="margin-top: 10px; margin-right: 5px; cursor: pointer">
							<mat-icon style="font-size: 23px; color: white" fontIcon="launch" [routerLink]="['', viewComponentGUID]" title="View all" aria-label="View all"> </mat-icon>
						</span>
						<span id="{{ elementIdPrefix + 'addEventBtn' }}" *ngIf="createToDoEvent" class="plus-icon" style="display: flex; align-self: flex-end">
							<mat-icon
								style="font-size: 23px"
								style="color: white"
								[routerLink]="['', addEditToDoListComponentGUID]"
								aria-hidden="false"
								title="Add an Event"
								aria-label="Add an Event"
								fontIcon="add_circle"
							>
							</mat-icon>
						</span>
					</div>
				</div>
				<div #cardBodyContainer class="card card-item" style="min-height: 198px;max-height: 198px;">
					<app-loader class="dashboardLoader" *ngIf="!toDoComponentLoaded" [spinnerName]="toDoSpinner"></app-loader>
					<ng-container *ngIf="toDoComponentLoaded">
						<div *ngIf="groupedToDoModelList && groupedToDoModelList.length <= 0" class="no-item-container"></div>
						<div *ngIf="groupedToDoModelList && groupedToDoModelList.length > 0" class="card-body card-body-item">
							<div class="to-do-list-item-container">
								<div class="to-list-item" *ngFor="let toDoModel of groupedToDoModelList">
									<div class="notif-category">
										<p>{{ toDoModel.eventCategory }}</p>
									</div>
									<div
										[class]="
											(itemModel.eventCategory === eventCategoryEnum.PASTDUE
												? 'past-due-event'
												: itemModel.eventCategory === eventCategoryEnum.UPCOMING
													? 'upcoming-event'
													: 'due-today-event') + ' my-day-per-notif'
										"
										*ngFor="let itemModel of toDoModel.items"
									>
										<div class="per-notif-f-box">
											<div class="notif-message">
												<ul class="to-list-item-list-container">
													<li class="to-do-list-item-list-set wrap-text">
														<a style="cursor: pointer" title="{{ itemModel.eventName }}" (click)="itemClicked(itemModel)" aria-label="Tooltip on hover">
															<span class="to-do-list-item">{{ itemModel.eventName }}</span>
														</a>
														<div style="padding-left: 20px; display: flex; flex-direction: column; text-overflow: ellipsis; white-space: pre; overflow: hidden">
															<a
																style="cursor: pointer; display: inline-block; margin-left: -10px"
																(click)="navigateToLink(itemModel)"
																title="{{ itemModel.eventName }}"
																class="wrap-text"
																aria-label="Tooltip on hover"
															>
																<span class="to-do-list-item" style="font-size: 10px">{{ itemModel.redirectUrlLabel }}</span>
															</a>
															<span class="status-date">{{ itemModel.eventType }}</span>
															<div>
																<span class="status-date"><i class="fa fa-calendar-o" aria-hidden="true"></i></span>
																<span class="status-date"> {{ itemModel.eventState + ' - ' }} </span>
																<span class="status-date">{{ itemModel.dueDate | date: 'MM/dd-yyyy HH:MM:SS' }}</span>
																<span class="status-date" *ngIf="itemModel.lastSnoozedLength">
																	- Snoozed till {{ itemModel.snoozeHistoryDate | date: 'MM-dd-yyyy' }}
																</span>
															</div>
														</div>
													</li>
												</ul>
											</div>
											<div class="icon-fbox">
												<div class="icon-spacing">
													<i
														*ngIf="itemModel.isSnoozeAllowed"
														title="Snooze Event"
														class="bx bx-alarm-snooze bx-sm header-icon"
														(click)="handleActionButtonClicked('snooze', true, itemModel)"
													></i>
												</div>
												<span *ngIf="!itemModel.nonToDoEvent" class="plus-icon icon-spacing" (click)="handleActionButtonClicked('complete', true, itemModel)">
													<mat-icon class="icon-bold" aria-hidden="false" title="Complete Event" aria-label="Completed" fontIcon="check"> </mat-icon>
												</span>
												<span *ngIf="!itemModel.nonToDoEvent" class="plus-icon" style="color: red" (click)="handleActionButtonClicked('cancel', true, itemModel)">
													<mat-icon class="icon-bold" aria-hidden="false" title="Cancel Event" aria-label="Cancelled" fontIcon="close"> </mat-icon>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>

import { MsalService } from '@azure/msal-angular';
import { InternalAppStorageService } from '../../../../../goldstar-share/src/app/services/internal-app-storage.service';
import { Injectable } from '@angular/core';
import { Observable, catchError, from, mergeMap, of } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { LocalStorageService } from '../../../../../goldstar-share/src/app/services/local.storage.service';
import { Mutex } from 'async-mutex';

@Injectable({
	providedIn: 'root',
})
export class TokenService {
	private mutex!: Mutex;
	private readonly appTokenKey = 'internalAPPTokenExpiryValue';
	private readonly appToken = 'internalAppToken';
	constructor(
		private storageService: InternalAppStorageService,
		private authService: MsalService,
		private localStorageService: LocalStorageService
	) {
		this.mutex = new Mutex();
	}

	/**
	 * Acquires a token either from cache or a fresh token from AD
	 * @returns
	 */
	acquireToken(): Observable<string> {
		const tokenExpiryFromCache = this.storageService.tryLoadItem<number>(this.appTokenKey);
		if (!tokenExpiryFromCache || tokenExpiryFromCache == null) {
			return this.renewToken();
		} else {
			const current_time = Math.round(Date.now() / 1000);
			if (tokenExpiryFromCache <= current_time) {
				return this.renewToken();
			} else {
				const tokenFromCache = this.storageService.tryLoadItem<string>(this.appToken);
				return of(tokenFromCache ?? '');
			}
		}
	}

	/**
	 * Fetches a new token from the auth provider
	 * The Logic:
	 * 	Tries to acquire token silent
	 * 	If Fails do hard login or else return token by acquiring and storing in cache.
	 *  DO NOT CHANGE OR UPDATE THIS METHOD WITHOUT HAVING A DISCUSSION WITH TIMOTHY PERCIVAL
	 * @returns
	 */
	private renewToken(): Observable<string> {
		let accessToken = '';

		// Puts a lock om token acquire, to avoid concurrent token renewal
		return from(this.mutex.acquire()).pipe(
			mergeMap((release: any) => {
				try {
					// Tries to get the token from cache, if expired tries to acquire via acquire token silent
					const tokenExpiryFromCache = this.storageService.tryLoadItem<number>(this.appTokenKey);
					const current_time = Math.round(Date.now() / 1000);
					if (tokenExpiryFromCache != null && tokenExpiryFromCache <= current_time) {
						let accounts = this.authService.instance.getAllAccounts();
						if (accounts?.length) {
							const accountInfo = accounts[0];
							const fetchTokenRequest = {
								scopes: ['user.read'],
								account: accountInfo,
							};
							this.clearUserToken();
							return this.authService
								.acquireTokenSilent(fetchTokenRequest)
								.pipe(
									mergeMap((accessTokenResponse) => {
										const accessToken = accessTokenResponse.accessToken;
										const decodedJWTToken = jwtDecode(accessToken)!;
										this.storageService.storeItem<number>(this.appTokenKey, decodedJWTToken.exp ?? 0);
										this.storageService.storeItem<string>(this.appToken, accessToken);
										release();
										return of(accessToken);
									})
								)
								.pipe(
									catchError((error: any) => {
										release();
										this.loginUsingRedirect();
										return of(accessToken);
									})
								);
						} else {
							release();
							this.loginUsingRedirect();
							return of(accessToken);
						}
					} else {
						// If token has not expired then get the updated token from storage and return it
						const token = this.storageService.tryLoadItem<string>(this.appToken);
						if (token) {
							accessToken = token;
							return of(accessToken);
						} else {
							// No token available, try acquiring token via redirect.
							release();
							this.loginUsingRedirect();
							return of(accessToken);
						}
					}
				} catch {
					release();
					this.loginUsingRedirect();
					return of(accessToken);
				}
			})
		);
	}

	loginUsingRedirect() {
		const redirectRequest = {
			scopes: ['user.read'],
		};
		// Should refresh the page and follow the happy path
		this.authService.acquireTokenRedirect(redirectRequest);
	}

	clearUserToken() {
		const allStorageKeys = this.localStorageService.allKeys();
		const allTokenStorageKeys = allStorageKeys.filter((key) => key.includes('msal') || key.includes('login.windows.net'));
		if (allTokenStorageKeys.length > 0) {
			allTokenStorageKeys.forEach((key: string) => {
				if (this.localStorageService.itemExists(key)) {
					this.localStorageService.removeItem(key);
				}
			});
		}
	}
}

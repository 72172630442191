import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { FavoriteMenuModel } from '../../models/models';
import { FavoriteMenuService } from '../../services/favorite-menu.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../models/classes';
import { favoriteMenuUpdated, menuLoadedState } from '../../../store/store.selector';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TippyCloseOnClickOutsideDirective } from '../../directives/tippy-close-on-click-outside.directive';
import { Subscription } from 'rxjs';

@Component({
	standalone: true,
	imports: [CommonModule, RouterModule, TippyCloseOnClickOutsideDirective],
	selector: 'app-side-navbar',
	templateUrl: './side-navbar.component.html',
	styleUrls: ['./side-navbar.component.scss'],
})
export class SideNavbarComponent implements OnInit, AfterViewInit, OnDestroy {
	public menuLoadedObserver$!: Subscription;
	public favoriteMenuUpdatedObserver$!: Subscription;

	public favoriteMenuModelList: FavoriteMenuModel[] = [];

	constructor(
		private menuService: FavoriteMenuService,
		private el: ElementRef,
		private tippyService: NgxTippyService,
		private store: Store<AppState>
	) {}

	async ngOnInit(): Promise<void> {
		this.initializeSubscription();
	}

	async initializeSubscription() {
		this.menuLoadedObserver$ = await this.store.select(menuLoadedState).subscribe(async (x) => {
			if (x) {
				this.favoriteMenuModelList = await this.menuService.prepareUserFavoriteMenu();
			}
		});

		// SUBSCRIPTION WHEN FAVORITE MENU IS UPDATED
		this.favoriteMenuUpdatedObserver$ = await this.store.select(favoriteMenuUpdated).subscribe(async (x) => {
			if (x) {
				this.favoriteMenuModelList = await this.menuService.prepareUserFavoriteMenu();
			}
		});
	}

	ngAfterViewInit(): void {}

	ngOnDestroy(): void {
		this.menuLoadedObserver$?.unsubscribe();
		this.favoriteMenuUpdatedObserver$?.unsubscribe();
	}

	close() {
		this.tippyService.hide('instance-12');
	}
}
